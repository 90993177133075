<script setup>

import car1 from '@/assets/images/statics/car1.jpg';
import car2 from '@/assets/images/statics/car2.jpg';
import car3 from '@/assets/images/statics/car3.jpg';
import forEntrepreneur from '@/assets/images/statics/dla_przedsiebiorcy.jpg';
import forConsumer from '@/assets/images/statics/dla_konsumenta.jpg';
import insurance from '@/assets/images/statics/ubezpieczenia.png';

useHead({
  title: "Oferta leasingowa - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
});

const backend = useBackend();
const {data: brands} = useAsyncData(async () => await backend.getBrands());

</script>

<template>
  <Container>

    <!-- header -->
    <div class="flex flex-col lg:flex-row">
      <div class="basis-1/2 shrink-0 flex-grow-0 order-2 lg:order-1">
        <div class="text-2xl text-center lg:text-left lg:text-5xl text-vehis-red">
          <h1>
            <span class="mb-2 lg:mb-6">SAMOCHÓD  <span class="font-light">w VEHIS</span></span>
            <span class="block"><span class="font-light">jest</span> taniej!</span>
          </h1>
        </div>
        <div class="text-xl text-center lg:text-left leading-normal pt-8 lg:pt-16">
          <span class="font-light">Największy wybór samochodów</span> z wysokimi rabatami.
          <div class="pt-6">
            <span class="font-light">Jeżeli masz już ofertę - wyślij ją do nas, a my ten sam samochód zaoferujemy Ci taniej.</span>
          </div>
        </div>
      </div>
      <div class="basis-1/2 shrink-0 flex-grow-0 order-1 lg:order-2">
        <img src="@/assets/images/statics/offercars.webp" alt="cars">
      </div>
    </div>

    <StaticHeader>Wybieraj spośród samochodów wszystkich marek</StaticHeader>

    <StaticCarousel class="sm:mx-10 lg:mx-12">
      <ButtonBrand v-for="brand in brands?.data"
                   :name=brand.name :icon=brand.svg
                   :href="{name: 'brand', params: {brand: brand.name.toLowerDashed()}}"

      />
    </StaticCarousel>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Dopasuj finansowanie do swoich potrzeb</StaticHeader>

    <div class="flex flex-col xl:flex-row gap-4 lg:gap-20 lg:my-20">
      <StaticArticleBox
        preheader="Jestem przedsiębiorcą"
        header="Potrzebuję samochodu do mojej firmy"
        :src="forEntrepreneur"
        buttonLabel="Sprawdź wszystkie korzyści"
        :href="{name: 'static-offer-entrepreneur'}">
        <p class="text-base font-extralight leading-normal inline m-0">
          Zyskaj wszystkie zalety klasycznego leasingu i dofinansowanie do ubezpieczenia.
        </p>
        <p class="text-base font-normal leading-normal inline m-0">
          Nawet od pierwszego dnia działalności.
        </p>
      </StaticArticleBox>

      <StaticArticleBox
        preheader="Nie prowadzę działalności gospodarczej"
        header="Kupuję samochód prywatnie"
        :src="forConsumer"
        buttonLabel="Sprawdź wszystkie korzyści"
        :href="{name: 'static-offer-consumer'}">
        <p class="text-base font-extralight leading-normal inline m-0">
          Zyskaj nowy samochód z takim samym rabatem jak przedsiębiorca, raty niższe niż w
          klasycznym kredycie i
        </p>
        <p class="text-base font-normal leading-normal inline m-0">
          nawet o 20% tańsze
        </p>
        <p class="text-base font-light leading-normal inline m-0">
          ubezpieczenie.
        </p>
      </StaticArticleBox>
    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>
      Nasza oferta łączy najlepsze cechy finansowania samochodu
    </StaticHeader>

<!--    <StaticLeasingCompare class="mt-4 mb-12 lg:flex" />-->
    <ContentLeasingFeaturesTable noButton />


    <StaticSeparator class="w-1/3" />

    <StaticHeader>
      Ubezpieczenie VEHIS, które w 100%
      <br>
      zabezpieczy Twój samochód
    </StaticHeader>

    <StaticArticleBox class="lg:mx-20 lg:my-12"
                      preheader="Ubezpieczenia VEHIS"
                      header="Pełny pakiet OC/AC/NNW + GAP"
                      buttonLabel="Dowiedz się więcej"
                      :src="insurance"
                      :href="{name: 'static-insurances'}"
                      right-side-image
    >
      <div class="font-light">
        W
        <span class="font-normal">pakiecie ubezpieczeń VEHIS</span> Twój samochód będzie miał pełną ochronę przez cały okres finansowania.
        Nawet gdyby w ostatnim roku umowy doszło do szkody całkowitej,
        <span class="font-normal">odzyskasz 100% wartości</span> z faktury zakupu samochodu.
      </div>
    </StaticArticleBox>

    <StaticSeparator class="!w-1/3" />

    <BlockHowTo />

    <div class="lg:mx-20 my-10">
      <FormContact standalone />
    </div>

    <StaticSeparator />

    <StaticHeader>Często zadawane pytania</StaticHeader>

    <div class="lg:mx-20 mt-6 mb-10">
      <StaticFaqItem question="Jakie dokumenty będą potrzebne do złożenia wniosku o finansowanie?">
        Jeżeli prowadzisz działalność gospodarczą wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam numer NIP swojej firmy, dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Jeżeli nie prowadzisz działalności oprócz wniosku i zgód poprosimy Cię dodatkowo o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.
      </StaticFaqItem>
      <StaticFaqItem question="Jak długo muszę prowadzić działalność, żeby dostać finansowanie w VEHIS?">
        W VEHIS finansujemy startupy już od pierwszego dnia działalności gospodarczej.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę skorzystać z oferty VEHIS, jeżeli nie prowadzę działalności gospodarczej?">
        Nie musisz być przedsiębiorcą, żeby skorzystać z naszej oferty. Finansujemy zarówno przedsiębiorców jak i konsumentów.
      </StaticFaqItem>
      <StaticFaqItem question="Na czym polega możliwość wymiany lub zwrotu samochodu przed końcem umowy?">
        W naszej umowie leasingu wprowadziliśmy możliwość jej przedterminowego zakończenia bez podawania przyczyny. W dowolnym momencie możesz zgłosić do nas chęć rozliczenia i zakończenia umowy. Zestawimy wówczas Twoje zobowiązanie z prognozowaną wartością samochodu (wstępna wycena na bazie Eurotax). Jeżeli pozostała do spłaty kwota przewyższy wartość samochodu, to wpłacisz nam różnicę w formie kaucji i możesz oddać samochód do VEHIS. Od tego momentu nie będziemy wystawiali kolejnych faktur i wystawimy Twój samochód na sprzedaż. Ty też możesz pozyskać Klienta we własnym zakresie! Po sprzedaży nastąpi rozliczenie, w którym dodatkowo uwzględniamy zwrot z niewykorzystanej części ubezpieczenia. Jeżeli po finalnym rozliczeniu zostanie nadwyżka to w całości Ci ją wypłacimy. Jeżeli przychód ze sprzedaży i zwrot z ubezpieczenia nie pokryją wartości zobowiązania będziesz musiał dopłacić różnicę (w tym celu wykorzystamy kaucję, o której piszemy powyżej). Po zamknięciu całej transakcji możesz wziąć leasing na inny interesujący Cię samochód lub po prostu zakończyć naszą współpracę.
      </StaticFaqItem>
      <StaticFaqItem question="Czy mogę sfinansować samochód spoza oferty VEHIS?">
        Choć oferta VEHIS obejmuje wszystkie najpopularniejsze segmenty i większość marek dostępnych w Polsce to może się zdarzyć, że swój upragniony samochód znajdziesz u innego dostawcy. Oczywiście my nadal bardzo chętnie go sfinansujemy! W VEHIS finansujemy samochody osobowe i dostawcze do 3,5 t, nowe i używane do 5 lat z maksymalnym przebiegiem 150 tys. km. Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża.
      </StaticFaqItem>
    </div>

    <StaticSeparator />

    <StaticHeader>O Grupie VEHIS</StaticHeader>

    <StaticTwoColumn>
      <template v-slot:one>
        VEHIS to platforma oferująca samochody wszystkich marek wraz z finansowaniem, ubezpieczeniem oraz wsparciem serwisowym.
        <br /><br />
        Jesteśmy polską firmą i działamy na terenie całego kraju. Siedziba główna VEHIS mieści się w Katowicach.
      </template>
      <template v-slot:two>
        Firmę założyli managerowie, którzy współtworzyli i rozwijali największe firmy leasingowe w Polsce. Dzięki zebranym doświadczeniom stworzyli produkt finansowy, który łączy najlepsze cechy leasingu, kredytu i najmu długoterminowego.
      </template>
    </StaticTwoColumn>

    <div class="flex justify-center lg:pt-8">
      <ButtonRounded color="gray" :href="{name: 'static-about'}" fit>Dowiedz się więcej o Grupie VEHIS</ButtonRounded>
    </div>

  </Container>
  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
